.wrapper {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .heading {
    font-size: 2.25rem;
    font-weight: 700;
  }
  
  .forgot_password {
    font-size: 0.75rem;
    font-weight: 600;
  }
  
  .password_input {
    position: relative;
  }
  
  .password_icon {
    position: absolute;
    top: 30%;
    right: 2%;
    cursor: pointer;
  }
  
  @media screen and (max-width: 920px) {
    .wrapper {
      padding: 0rem;
    }
    .heading {
      font-size: 1.25rem;
    }
  }
  