.wrapper {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to right, #662d91 35%, #fff 35%);
  background-repeat: no-repeat;
  display: flex;
  padding: 3rem;
}

.left_wrapper {
  background: url(../../assets/svgs/auth_bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.left_wrapper,
.right_wrapper {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 920px) {
  .left_wrapper {
    display: none;
  }
  .wrapper {
    background: #fff;
  }
}
