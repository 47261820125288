.input {
  background-color: #ffffff !important;
  border: 1px solid var(--border);
  border-radius: 8px;
  width: 100%;
  outline: none;
  padding: 12px 16px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
}

.input_main_div {
  position: relative;
  .display_icon {
    position: absolute;
    top: 12px;
    right: 16px;
  }
}

.input_label {
  font-weight: 400;
  margin-bottom: 1px;
  font-size: 16px;
}

.input::placeholder {
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #aaaaaa;
}

.editable {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.valid_errormessage {
  font-size: 12px;
  padding-top: 3px;
  color: red;
}

.inner-input-div {
  margin-top: -60px;
  display: flex;
}
