.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  height: 4rem;
  width: 100%;
  border-bottom: 0.5px solid #dadada;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}

.search_wrapper {
  position: relative;
}

.search_results {
  position: absolute;
  left: 0;
  background-color: #fff;
  border: 0.1px solid gray;
  width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 5px;
}

.search_link {
  text-decoration: none;
  color: inherit;
  display: block;
  border-bottom: 1px solid gray;
  padding: 0.2rem;
}

.search_link > p {
  margin: 0;
}

.header section,
.profile_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.profile_text {
  padding-top: 1rem;
}

.profile_container div h3,
.profile_container div p {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.00438rem;
}

.profile_pic_container {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

@media screen and (max-width: 920px) {
  .search,
  .profile_container {
    display: none;
  }

  .hamburger {
    display: inline-block;
  }
}
