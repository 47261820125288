.main_container {
  display: flex;
}

.outlet {
  padding: 5rem 1rem;
  /* remove width of menu bar */
  width: calc(100% - 16.25rem);
  margin-left: auto;
}

/* collapsed styles */

.collapsed_outlet {
  width: calc(100% - 6rem);
}

@media screen and (max-width: 920px) {
  .outlet {
    width: 100%;
  }
}
