.SelectContainer {
  position: relative;
  margin: 0;
}

.SelectLabelButton {
  border: 1px solid rgba(127, 145, 168, 0.4);
  border-radius: 4px;
  width: 100%;
  outline: none;
  padding: 0 19px;
  background-color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c4c4c4;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.label {
  font-size: 1rem;
  color: #353f50;
  margin-bottom: 0.5rem;
}

.DropdownStyle {
  max-height: 40vmax;
  min-width: 10rem;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fafafa;
  border: 1.5px solid slategrey;
  transition: max-height 0.2s ease;
  overflow: scroll;
}

.full_dropdown {
  border: 1px solid #f5f6fa;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 4px 15px rgba(172, 173, 196, 0.2);
  border-radius: 6px;
}

.description_head {
  border-bottom: 1px solid #f5f6fa;
  justify-content: space-between;
  padding: 18px 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
}

.currentValue {
  color: #333754;
}

.placeholder {
  color: #c4c4c4;
}

.DropdownItem {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: #333754;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
  padding: 18px 20px;
  border-bottom: 1px solid #f5f6fa;
}

.valid_errormessage{
  font-size: 12px;
padding-top: 3px;
color: red;
} 