.container {
  height: 100vh;
  background-color: var(--light);
  border-radius: 5px;
  padding: 20px;
}
.noAccessCard {
  display: flex;
  background-color: #f1f1f9;
  padding: 30px;
}
.noAccessCard h4 {
  color: #ff0000;
}
.noAccessCard p {
  color: var(--primary);
}
.noAccessCard img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
