.add_btn {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  white-space: nowrap;
}

.display {
  width: 22.75rem;
  height: 19.125rem;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 0.25rem;
  border: 0.8px solid #dbdfe4;
  background: #fff;
}

.display_image_wrapper {
  height: 13.875rem;
  border-radius: 0.625rem;
  margin-bottom: 1.2rem;
  overflow: hidden;
}

.display_name {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.modal_title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: -0.03125rem;
  margin-bottom: 0;
}

.modal_subtitle {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.add_advert_btn {
  width: 10.5rem;
  height: 3.125rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

@media screen and (max-width: 920px) {
  .adverts_item_container {
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  .inputs_wrapper {
    flex-wrap: wrap;
  }
}
