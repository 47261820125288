/* make the customizations */
$theme-colors: (
  'primary': #662d91,
  'warning': #ffd900,
  'dark': #1c201f,
  'secondary': #9fa6b2,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus,
.form-select:focus,
.form-control:focus {
  border-color: none;
  box-shadow: none;
  outline: 0 none;
  border: 1px solid #dbdfe4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Raleway, Sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

// Calendar customization

abbr[title] {
  text-decoration: none !important;
  font-family: Raleway !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.125rem !important;
  letter-spacing: 0.01rem !important;
  text-transform: capitalize !important;
}

.react-calendar__tile--now {
  background-color: inherit !important;
}

.react-calendar__tile--active {
  color: #662d91 !important;
  border-bottom: 0.5rem solid #662d91 !important;
  font-weight: bold !important;
  background-color: #e0cfec !important;
}

.react-calendar__tile:disabled {
  background-color: #fff !important;
  color: #aaaaaa !important;
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label__labelText--from {
  text-align: center;
  font-family: Raleway;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.125rem;
  letter-spacing: 0.01rem;
  white-space: nowrap;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s;
}
