.add_btn {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 7.75rem;
  gap: 0.2rem;
  white-space: nowrap;
}

.filter_btn {
  color: #2c2a3b;
}

.checkbox,
.table_div_top_checkbox {
  box-shadow: none;
  outline: none;
}

.checkbox:checked,
.table_div_top_checkbox:checked {
  background-color: #9fa6b2;
  outline: none;
  box-shadow: none;
}

.name_td {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  display: flex;
  gap: 1rem;
}

.td_img {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 6.1875rem;
  overflow: hidden;
}

.modal_title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: -0.03125rem;
  margin-bottom: 0;
}

.modal_subtitle {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.add_user_btn {
  width: 10.5rem;
  height: 3.125rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.add_another {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.add_another span {
  padding: 0.1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.users_list {
  border-radius: 0.5rem;
  border: 1px solid #dadada;
  background: #dadada;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.users_list p {
  margin: 0;
}

@media screen and (max-width: 920px) {
  .inputs_wrapper {
    flex-wrap: wrap;
  }
}
