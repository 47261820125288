.add_btn {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 7.75rem;
  gap: 0.2rem;
  white-space: nowrap;
}

.filter_btn {
  color: #2c2a3b;
}

.checkbox,
.table_div_top_checkbox {
  box-shadow: none;
  outline: none;
}

.checkbox:checked,
.table_div_top_checkbox:checked {
  background-color: #9fa6b2;
  outline: none;
  box-shadow: none;
}

.name_td {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  display: flex;
  gap: 1rem;
}

.td_img {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 6.1875rem;
  overflow: hidden;
}

.modal_title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: -0.03125rem;
}

.body_header {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  font-feature-settings:
    'clig' off,
    'liga' off;
  color: #4d4d4d;
  margin-bottom: 0.5rem;
}

.modal_dialog {
  max-width: 37.3125rem;
}

.body_text {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  word-wrap: break-word;
}

.modal_flex_divs {
  width: 45%;
}

@media screen and (max-width: 920px) {
  .modal_flex_divs {
    width: 100%;
  }
}
