.container {
  display: flex;
  width: 25.125rem;
  padding: 0rem 1rem 0rem 1rem;
  align-items: center;
  gap: 0.35rem;
  border-radius: 0.5rem;
  border: 1px solid #d9dbe9;
  background: #f9fafb;
}

.input{
    width: 100%;
    background: #f9fafb;
    border: none;
}

.input:focus{
  border: none;
  box-shadow: none;
}