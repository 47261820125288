.dashboard_header {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.01rem;
  margin-bottom: 1rem;
}

.dash_data_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.dash_data {
  display: flex;
  height: 9.0625rem;
  padding: 0rem 1.5625rem;
  align-items: center;
  gap: 1.5625rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  border: 1px solid #dbdfe4;
  background: #fff;
}

.dash_data_icon1,
.dash_data_icon2,
.dash_data_icon3 {
  display: flex;
  width: 6.25rem;
  height: 6.25rem;
  padding: 2.1875rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background: #f4edf9;
}

.dash_data_icon2 {
  background: #fefae1;
}

.dash_data_icon3 {
  background: #eef5fd;
}

.dash_data_header {
  font-size: 2.875rem;
  font-weight: 500;
  margin: 0;
}

.dash_data_header + p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.dash_data_percent {
  color: #34c636;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.chart_conatiner {
  width: 100%;
  height: 20.9375rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfe4;
  background: #fff;
  margin: 1rem 0;
  padding: 1rem;
}

.chart_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.chart_header_text h3 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
}

.chart_header_text p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.025rem;
  color: #a5a5a5;
}

.chart_dropdown {
  font-size: 0.875rem;
  font-weight: 400;
}

.chart_area {
  height: calc(100% - 3rem);
  padding-bottom: 1rem;
}

.bookings_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.bookings {
  grid-column: span 2;
}

.bookings,
.top_bookings {
  padding: 1.875rem 1.5625rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfe4;
  background: #fff;
  /* height: 24.25rem; */
}

.top_bookings {
  padding: 1.25rem;
}

.bookings_headings h3 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 150%;
}

.bookings_headings p {
  font-size: 0.66056rem;
  font-weight: 500;
  line-height: 1.45325rem;
}

.bookings_subheading {
  padding: 0.2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bookings_subheading_active {
  background-color: #f4edf9;
  color: #662d91;
}

.top_bookings_header {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.03125rem;
}

.top_bookings_items {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.03125rem;
}

.view_all {
  display: flex;
  justify-content: flex-end;
}

.view_all p {
  cursor: pointer;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.view_all p > * {
  color: inherit;
  text-decoration: inherit;
}

.bookings_table_head {
  font-size: 0.75rem;
  font-weight: 400;
  color: #a5a5a5 !important;
}

.bookings tbody {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.table_status {
  padding: 0.2rem 1rem;
  mix-blend-mode: multiply;
  /* background-color: #e8f4ed;
  color: #188e47; */
  border-radius: 0.5rem;
  white-space: nowrap;
}

@media screen and (max-width: 920px) {
  .bookings_container {
    grid-template-columns: 1fr;
  }

  .bookings,
  .top_bookings {
    grid-column: unset;
    height: unset;
    width: 100%;
    overflow: auto;
  }

  .bookings_headings {
    overflow: auto;
  }
}
