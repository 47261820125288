.main_btn,
.main_btn:hover,
.main_btn:focus {
  display: flex;
  max-height: 3rem;
  padding: 0.75rem;
  align-items: center;
  gap: 1.125rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid #dbdfe4;
  background: #fff;

  color: #6e7191;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: SofiaPro;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: capitalize;
}
