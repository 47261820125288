.table {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.07);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d6dde0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.title {
  height: 3.75rem;
  display: flex;
  align-items: center;
  color: #0d0f11;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
}

.table_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.1875rem;
  font-size: 1rem;
  font-weight: 500;
}

.entries_box {
  width: 3.75rem;
  height: 2.1875rem;
  background-color: #edf2f6;
  text-align: center;
  border: 1px solid rgba(128, 128, 128, 0.25);
  border-radius: 4px;
  margin: 1rem 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  outline: none;
}

.search_box {
  width: 17.3125rem;
  height: 2.1875rem;
  border: 1px solid #80808040;
  border-radius: 4px;
  padding: 0.5625rem 0.68rem;
  font-size: 14px;
  letter-spacing: 0.01rem;
  outline: none;
  background: #f1f1f9;
}

.search_box::placeholder {
  color: #7f91a8;
  font-size: 14px;
}

.table_wrapper {
  overflow-x: auto;
  /* min-height: 50vh; */
  height: auto;
}

.table_wrapper table {
  width: 100%;
}

.table_wrapper td,
.table_wrapper th {
  font-size: 0.875rem;
  padding: 1.2462rem;
}

.table_wrapper td {
  background-color: white;
}

.table_wrapper th {
  white-space: nowrap;

  border-bottom: 0.5px solid #dbdfe4;
  background: #d8d8d8;

  height: 2.875rem !important;
  color: #14142b;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.625rem;
}

.table_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2.125rem;
  padding-right: 1.25rem;
  height: 60px;
  align-items: center;
  padding-top: 12px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

.pagination_action {
  height: 1.875rem;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.3125rem 0.75rem;
  color: #662d91;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.pagination_button {
  height: 2rem;
  text-align: center;
  padding: 0.5rem;
  color: #999ca0;
  cursor: pointer;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
}

.pagination_button:hover {
  transform: scale(0.95);
}

.pagination_button_active {
  border-radius: 8px;
  background-color: #d8d8d8 !important;
  border: 1px solid rgba(128, 128, 128, 0.25);
  color: #fff;
}

@media (max-width: 577px) {
  .search_box {
    display: none;
  }
}
