.add_btn {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  white-space: nowrap;
}

.filter_btn {
  color: #2c2a3b;
}

.checkbox,
.table_div_top_checkbox {
  box-shadow: none;
  outline: none;
}

.checkbox:checked,
.table_div_top_checkbox:checked {
  background-color: #9fa6b2;
  outline: none;
  box-shadow: none;
}

.name_td {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  display: flex;
  gap: 1rem;
}

.td_subdata {
  color: #999ca0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.booking_status {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: capitalize;
}

.steps_header {
  display: block;
  width: 75%;
  margin: auto;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.stepper_wrapper {
  width: 85%;
  height: 0.1rem;
  background-color: #aaaaaa;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper_texts {
  width: 100%;
  padding-top: 1rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1rem;
  font-weight: 400;
}

.stepper_texts p {
  margin: 0;
}

.stepper_wrapper_2 {
  background-image: linear-gradient(to right, #662d91 50%, #aaaaaa 50%);
}

.stepper_wrapper_last {
  background-image: none;
  background-color: #662d91;
}

.stepper {
  height: 1.5rem;
  width: 1.5rem;
  background-color: #fff;
  border: 2px solid #aaaaaa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepper_active {
  border-color: #662d91;
}

.stepper_icon {
  height: 0.75rem;
  width: 0.75rem;
  background-color: #662d91;
  border-radius: 50%;
  margin: auto;
}

.calendar_wrapper {
  width: 18rem;
}

.calendar {
  color: #161616;
  text-align: center;
  font-family: Raleway;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.125rem;
  letter-spacing: 0.01rem;
  text-decoration: none !important;

  border-radius: 0.25rem;
  border: 1.5px solid#DADADA !important;
  background: #fff;
}

.time_tile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0.2rem;

  border-radius: 0.25rem;
  border: 1.5px solid #dadada;
  background: #fff;
  width: 18rem;
  padding: 0.625rem 0rem;
  cursor: pointer;
}

.time_tile p {
  margin: 0;
}

.calendar_tile {
  color: #2c2a3b !important;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.01rem;
}

.wrapper h2 {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.03125rem;
  color: #000;
}

.wrapper p {
  color: #59595e;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.step_2 {
  border: 1px solid #dadada;
  padding: 1rem;
}

.step3_key {
  /* color: rgba(0, 0, 0, 0.56) !important; */
  font-feature-settings:
    'clig' off,
    'liga' off;
    font-size: 1.125rem !important;
    font-weight: 400 !important;
    line-height: 1.75rem;
}

.step3_value {
  color: #14142b !important;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem;
}

.view_bookings_header{
  display: flex;
  align-items: flex-start;
  margin: 0 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #DADADA;
}

.view_bookings_body{
  margin: 0 1rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 920px) {
  .steps_header {
    width: 100%;
  }

  .inputs_wrapper {
    flex-wrap: wrap;
  }
}
