.dialog {
  width: 28rem;
  height: 21.25rem;
}

.gif_wrapper {
  width: 5rem;
  padding: 2rem 0;
}

.title {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.descrption {
  color: #777;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.success_btn {
  display: flex;
  width: 20.8125rem;
  height: 3.125rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

@media screen and (max-width: 920px){
    .dialog {
        width: unset;
      }
      
      .success_btn{
        width: 18rem;
      }
}