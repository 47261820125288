.menu {
  width: 16.25rem;
  height: calc(100vh - 4rem);
  padding: 1rem 2rem 9.375rem 2rem;
  overflow: auto;
  border-right: 1px solid #dbdfe4;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 4rem;
  z-index: 1000;
}

.collapse {
  display: flex;
  align-items: center;
}

.collapse div {
  width: 7rem;
  height: 0.1rem;
  background-color: #dbdfe4;
  align-self: center;
}

.collapse_icon {
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0.4rem 0.6rem;
  border-radius: 50%;
  /* Light/shadow-sm */
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 6px 6px -6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.collapse_text {
  align-self: flex-end;
  padding-bottom: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.pages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.3rem;
  border-bottom: 1px solid #dbdfe4;
  padding: 0.5rem 0;
}

.nav {
  text-decoration: none;
  color: inherit;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.active {
  background: #d8d8d8;
}

.help_and_support {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.logout {
  margin-top: 5rem;
}

/* collapse styles */

.collapsed_menu {
  width: 6rem;
  padding: 1rem 1rem 9.375rem 1rem;
}

.collapsed_nav {
  justify-content: center;
}

.collapsed_icon {
  transform: scaleX(-1);
}

@media screen and (max-width: 920px) {
  .menu {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 4rem;
    animation: MoveIn;
    animation-duration: 0.7s;
  }

  @keyframes MoveIn {
    from {
      left: -100%;
    }
    to {
      left: 0;
    }
  }

  .hide_mobile_menu {
    left: -100%;
    animation: MoveOut;
    animation-duration: 0.7s;
  }

  @keyframes MoveOut {
    from {
      left: 0;
    }
    to {
      left: -100%;
    }
  }
}
